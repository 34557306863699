import React, { useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import PageHeader from "../../components/PageHeader/PageHeader";

const Events = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [content, setContent] = useState({ events: [] });

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        if (!localStorage.getItem("studentId")) {
          throw new Error("No studentId found in localstorage");
        }

        const studentId = await localStorage.getItem("studentId");
        const apiUrl = await `${process.env.REACT_APP_API_URL}/students/${studentId}/`;
        const res = await axios.get(apiUrl);

        if (res.status !== 200) {
          throw new Error("Didn't get a 200 from the API");
        }
        await setContent(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } catch (error) {
        await setLoading(false);
        setError(error);
        //TODO: alert user to error
        window.alert(error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <div>
      <>
        <PageHeader
          image={
            "https://oday.endeavour.edu.au/media/Events_and_webinars_-_main_image_vnwkhwJ.jpg"
          }
          title={"Schedule"}
          description={"Check out the upcoming Orientation Day events"}
        />
      </>

      <section className="w-full p-2 md:px-32 lg:px-64 xl:px-64 text-center border-r">
        <div className="flex justify-center flex-wrap">
          {loading ? (
            <Loading loading={loading} />
          ) : error ? (
            "Something went wrong :("
          ) : (
            content.events.map((event, index) => (
              <Card details={event} type="event" key={index} />
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default Events;
