import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Link,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";

import Content from "../Content/Content";
import Events from "../Events/Events";
import Welcome from "../Welcome/Welcome";
import Links from "../Links/Links";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

const Home = () => {
  let history = useHistory();
  const [showBottomBanner, setShowBottomBanner] = useState(false);

  const logout = async () => {
    await localStorage.clear();
    history.push("/");
  };

  useEffect(() => {
    setTimeout(() => {
      setShowBottomBanner(true);
    }, 7000);
  }, []);

  return (
    <div className="w-full border-t-4 border-wisdom">
      <Router>
        <nav className="bg-chia w-full  md:px-32">
          <ul className="flex flex-wrap items-center bg-chia py-4 px-2 w-full justify-around">
            <li className="mr-1">
              <Link
                to="/home"
                role="button"
                className="bg-transparent hover:bg-tumeric text-white hover:text-white font-normal rounded text-sm py-2 px-2"
              >
                Home
              </Link>
            </li>
            <li className="mr-1">
              <Link
                to="/events"
                role="button"
                className="bg-transparent hover:bg-tumeric text-white hover:text-white font-normal rounded text-sm  py-2 px-2"
              >
                Schedule
              </Link>
            </li>
            <li className="mr-1">
              <Link
                to="/content"
                role="button"
                className="bg-transparent hover:bg-tumeric text-white hover:text-white font-normal rounded text-sm py-2 px-2"
              >
                Content
              </Link>
            </li>
            <li className="mr-1">
              <Link
                to="/useful-links"
                role="button"
                className="bg-transparent hover:bg-tumeric text-white hover:text-white font-normal rounded text-sm py-2 px-2"
              >
                Useful Links
              </Link>
            </li>
          </ul>
        </nav>

        <Switch>
          <Route path="/home">
            <Welcome />
          </Route>
          <Route exact path="/content">
            <Content />
          </Route>
          <Route path="/events">
            <Events />
          </Route>
          <Route path="/useful-links">
            <Links />
          </Route>
        </Switch>
      </Router>
      <div
        className={
          "fixed bottom-0 flex justify-center w-full h-auto py-2 px-8 md:px-16 bg-chia z-50 text-white " +
          (showBottomBanner ? "block" : "hidden")
        }
      >
        <div className="absolute top-0 right-0 p-4 px-2">
          <button
            onClick={() => setShowBottomBanner(false)}
            classname="font-semibold text-lg hover:pointer"
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
        </div>
        <div classname="flex flex-col justify-center w-full md:w-1/2">
          <p className="mb-2 text-base font-semibold md:text-xl px-2">
            Endeavour students receive two FREE treatments at their campus'
            Wellness Clinic and 15% off all dispensary/retail items.
          </p>
          <p className="mb-4 text-base md:text-xl px-2">
            Either call your local clinic or visit in person to book your
            appointment - all you need is your student number.
          </p>
          <div className="flex items-center justify-center gap-4 p-2">
            <a
              href="https://www.endeavourclinic.com.au/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-joy mr-2"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
      <footer className="bottom-0 p-4 bg-chia mt-32">
        <div className="flex flex-col w-full md:w-3/4 p-8">
          <div className="mb-4">
            <a
              href="https://www.endeavour.edu.au"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src="https://oday.endeavour.edu.au/media/endeavour_digital_left_white_yw9Tv5U.png"
                alt=""
                className="h-16 w-auto"
              />
            </a>
          </div>
          <div className="text-offwhite text-sm">
            <p className="mb-2">
              Endeavour College of Natural Health is the largest private Higher
              Education provider of natural medicine courses in the Southern
              Hemisphere. We support our students with lecturers from the
              forefront of the complementary medicine industry, cutting edge
              curricula, and Australia’s largest choice of
              natural&nbsp;health&nbsp;degrees.
            </p>
            <p className="mb-2">
              Endeavour College of Natural Health acknowledges the Australian
              Aboriginal and Torres Strait Islander peoples as the first
              inhabitants of the nation and the Traditional Custodians of the
              lands where we live, learn&nbsp;and&nbsp;work.
            </p>
            <p className="mb-2">
              © Australian College of Natural Medicine Pty Ltd T/A Endeavour
              College of Natural Health.RTO: 31489 | CRICOS: 00231G - NAT | HEP:
              PRV12070
            </p>
            <Link
              onClick={() => logout()}
              to=""
              role="button"
              className="btn-trans"
            >
              Logout
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
