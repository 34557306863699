import React from "react";

const Tile = ({ tile, index }) => {
  return (
    <div
      style={
        tile.image
          ? {
              backgroundImage: `linear-gradient(rgb(88, 92, 59, 0.8), rgb(88, 92, 59, 0.8)), url(${tile.image.image})`,
            }
          : {
              background: `linear-gradient(rgb(88, 92, 59, 0.8), rgb(88, 92, 59, 0.8))`,
            }
      }
      className={
        "flex items-center justify-center rounded overflow-hidden shadow-md hover:shadow-lg bg-wisdom text-white hover:bg-wisdom hover:text-white h-64  w-full bg-cover bg-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 hover:cursor-pointer"
      }
    >
      <div className="px-6 py-4 text-center">
        <div className="font-normal text-xl mb-2">{tile.title}</div>
        <p className="text-base">{tile.description}</p>
      </div>
    </div>
  );
};

export default Tile;
