import React, { useState, useEffect } from "react";
import VideoModal from "../VideoModal/VideoModal";
import moment from "moment-timezone";
import "moment-precise-range-plugin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";

moment.tz.setDefault("UTC");

const Card = ({ details, type, index }) => {
  const [showModal, setShowModal] = useState(false);
  const [dateDiff, setDateDiff] = useState();

  const [eventPast, setEventPast] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formattedStartDate, setFormattedStartDate] = useState("");
  const [dateTimes, setDateTimes] = useState({});

  const joinWebinar = async () => {
    try {
      const eventId = details.id;
      const studentId = await localStorage.getItem("studentId");
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/students/event-view/`,
        data: { student_id: studentId, event_id: eventId },
      });
    } catch (error) {
      console.log(error);
    }

    window.location.replace(details.url);
  };

  const parseDateTime = () => {
    if (!details.start_date) {
      return;
    }

    const startDateTime = moment(details.utc_start).local();
    const endDateTime = moment(details.utc_end).local();
    setFormattedStartDate(endDateTime.format("ddd MMM Do YYYY"));

    setDateTimes({
      startDate: startDateTime.format("ddd MMM D"),
      startTime: startDateTime.format("h:mmA"),
      endTime: endDateTime.format("h:mmA"),
      timeZone: moment.tz(moment.tz.guess()).zoneAbbr(),
    });

    //countdown timer
    let x = setInterval(async () => {
      const now = moment().local();
      await setDateDiff(now.preciseDiff(startDateTime));
      const preciseDateDiff = now.preciseDiff(startDateTime, true);

      //if event is in the next 30 minutes then allow joining
      const dateDiffObj = now.preciseDiff(startDateTime, true);

      if (
        dateDiffObj.minutes <= 30 &&
        dateDiffObj.hours === 0 &&
        dateDiffObj.days === 0
      ) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }

      if (preciseDateDiff.firstDateWasLater) {
        clearInterval(x);
        setDateDiff();

        setIsOpen(true);
      }

      //opacity added if event in the past
      const preciseEndDateDiff = now.preciseDiff(endDateTime, true);
      if (preciseEndDateDiff.firstDateWasLater) {
        setEventPast(true);
        setIsOpen(false);
      }

      const preciseStartDateDiff = now.preciseDiff(startDateTime, true);
      if (preciseStartDateDiff.firstDateWasLater) {
        setIsOpen(true);
      }
    }, 1000);
  };

  useEffect(() => {
    if (type === "event") {
      parseDateTime();
    }

    // eslint-disable-next-line
  }, [type]);

  const toggleModal = () => {
    setShowModal(!showModal);
    return showModal;
  };

  return (
    <>
      <div
        className={`content-card flex flex-wrap items-center w-full lg:w-3/4 pb-4  h-full lg:mb-8 mb-16 rounded shadow-md hover:shadow-lg`}
        key={index}
        data-contenttype={details.content_type}
        style={{ opacity: eventPast ? "0.3" : "1.0" }}
      >
        <div className="w-full h-full">
          <img
            className="rounded-tr rounded-tl object-cover h-72 w-full"
            src={
              details.image
                ? details.image.image
                : "https://www.endeavour.edu.au/media/images/Young-woman-working-at-home-1035789156_6720x448.original.jpg"
            }
            alt=""
            loading="lazy"
          />
        </div>

        <div className="w-full px-2">
          <div className="px-6 text-left">
            <h2 className="text-xl my-3 font-normal">{details.title}</h2>
            {type === "event" ? (
              <h5 className="text-sm my-3 font-normal">
                <FontAwesomeIcon icon={faClock} /> {formattedStartDate}
                <br />
                {dateTimes.startTime} - {dateTimes.endTime} {dateTimes.timeZone}
                <br />
                {dateDiff ? `(Starts in ${dateDiff})` : null}
              </h5>
            ) : null}
            <p
              className="text-sm font-normal w-full h-full text-left"
              dangerouslySetInnerHTML={{ __html: details.description }}
            ></p>
          </div>
          <div className="w-full px-4 mt-8">
            {type === "content" ? (
              details.content_type === "2" ? (
                <button
                  onClick={() => toggleModal()}
                  rel="noopener noreferrer"
                  className="btn-tumeric"
                >
                  Watch Video
                </button>
              ) : (
                <a
                  role="button"
                  disabled={details.url ? true : false}
                  href={details.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-tumeric"
                >
                  Read More
                </a>
              )
            ) : details.is_open === true || isOpen ? (
              <button
                onClick={() => joinWebinar()}
                rel="noopener noreferrer"
                className="btn-tumeric"
              >
                Register for Event
              </button>
            ) : (
              <button
                disabled={true}
                className="btn-earth"
                style={{
                  cursor: eventPast || !isOpen ? "default" : "pointer",
                }}
              >
                {eventPast ? "Event Finished" : `Join Webinar Soon`}
              </button>
            )}
          </div>
        </div>
      </div>
      <VideoModal
        display={showModal}
        toggleModal={toggleModal}
        details={details}
      />
    </>
  );
};

export default Card;
