import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import Tile from "../../components/Tile/Tile";
import PageHeader from "../../components/PageHeader/PageHeader";

const Links = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [content, setContent] = useState([]);

  useEffect(() => {
    const getHomeContent = async () => {
      try {
        const apiUrl = await `${process.env.REACT_APP_API_URL}/content/links/`;
        const res = await axios.get(apiUrl);

        if (res.status !== 200) {
          throw new Error("Didn't get a 200 from the API");
        }

        await setContent(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } catch (error) {
        await setLoading(false);
        setError(error);
        window.alert(error);
      }
    };
    getHomeContent();
  }, []);

  return (
    <div>
      <>
        <PageHeader
          image={
            "https://www.endeavour.edu.au/media/images/Young-woman-working-at-home-1035789156_6720x448.original.jpg"
          }
          title={"Useful Links"}
          description={
            "All the essential pages and resources to get you through your studies"
          }
        />
      </>
      <section className="w-full sm:w-1/1 md:w-1/1 p-2 md:px-32 lg:px-64 text-center border-r">
        <div className="flex flex-wrap justify-center">
          {loading ? (
            <Loading loading={loading} />
          ) : error ? (
            "Something went wrong :("
          ) : (
            content.map((tile, index) => (
              <div
                className="m-auto w-full md:w-1/2 px-4 lg:mb-4 mb-8 h-auto"
                key={index}
              >
                {tile.internal_link ? (
                  <Link to={tile.internal_link}>
                    <Tile tile={tile} />
                  </Link>
                ) : (
                  <a
                    href={tile.external_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Tile tile={tile} />
                  </a>
                )}
              </div>
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default Links;
