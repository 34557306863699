import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

const Register = () => {
  let history = useHistory();
  const { register, handleSubmit } = useForm();

  const [isRegistering, setIsRegistering] = useState(false);
  const [registrationErrors, setRegistrationErrors] = useState();
  const [modalities, setModalities] = useState([]);

  useEffect(() => {
    getModalities();
  }, []);

  const getModalities = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/modalities`
      );
      const formattedModalities = res.data.map((modality) => {
        modality.name = modality.title;
        modality.value = modality.id;
        return modality;
      });
      setModalities(formattedModalities);
    } catch (error) {
      console.log(
        "error getting modalities - use predefined: " + error.message
      );
      setModalities([
        { name: "Diploma of Health Science", value: 12 },
        { name: "Undergraduate Certificate in Lifestyle Coaching", value: 10 },
        {
          name: "Undergraduate Certificate in History and Philosophy of Complementary Medicine",
          value: 9,
        },
        { name: "Undergraduate Certificate in Human Biology", value: 8 },
        {
          name: "Undergraduate Certificate in Building Health Through Nutrition",
          value: 7,
        },
        { name: "Non-Award Study", value: 6 },
        { name: "Bachelor of Complementary Medicine", value: 5 },
        { name: "Bachelor of Health Science (Myotherapy)", value: 4 },
        { name: "Bachelor of Health Science (Acupuncture)", value: 3 },
        {
          name: "Bachelor of Health Science (Nutritional and Dietetic Medicine)",
          value: 2,
        },
        { name: "Bachelor Of Health Science (Naturopathy)", value: 1 },
      ]);
    }
  };

  const registerUser = async (data) => {
    setIsRegistering(true);
    try {
      const payload = {
        first_name: data.first_name,
        last_name: data.last_name,
        email_address: data.email_address,
        campus: data.campus,
        modality: data.modality,
      };

      const apiUrl = `${process.env.REACT_APP_API_URL}/students/register/`;

      const res = await axios({
        method: "POST",
        url: apiUrl,
        data: payload,
        headers: { "Content-Type": "application/json" },
      });

      if (res.status !== (await 201)) {
        throw Error("Couldnt register you");
      }

      const studentId = res.data.details[0].modality_id;
      const modalityId = res.data.details[0].modality;

      setTimeout(async () => {
        await localStorage.setItem("studentId", studentId);
        await localStorage.setItem("modalityId", modalityId);

        setIsRegistering(false);
        history.push("/home");
      }, 2000);
    } catch (error) {
      setRegistrationErrors("That didn't work - please try again");
      setIsRegistering(false);
      window.scroll(0, 0);
    }
  };

  const campusesChoices = [
    { value: 1, name: "Adelaide" },
    { value: 2, name: "Brisbane" },
    { value: 3, name: "Gold Coast" },
    { value: 4, name: "Melbourne" },
    { value: 5, name: "Perth" },
    { value: 6, name: "Sydney" },
  ];

  return (
    <div
      className="px-auto py-auto min-h-screen h-full w-full bg-earth bg-cover pb-16"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://www.endeavour.edu.au/media/images/Young-woman-working-at-home-1035789156_6720x448.original.jpg)`,
      }}
    >
      <section className="m-auto py-8 px-4 w-full md:w-1/2 h-auto">
        <form
          onSubmit={handleSubmit(registerUser)}
          className="mb-4 bg-white p-8 rounded"
        >
          <div className="mb-4 text-center">
            <h1 className="">Register</h1>
            {registrationErrors ? (
              <div
                className="flex items-center bg-joy text-white text-sm font-bold px-4 py-3 w-full mb-2 mt-2 rounded"
                role="alert"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="https://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                </svg>
                <p>{registrationErrors}</p>
              </div>
            ) : null}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm mb-2">
              First Name
            </label>
            <input
              name="first_name"
              ref={register({ required: true })}
              className="text-box"
              placeholder="Your"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm mb-2">
              Last Name
            </label>
            <input
              name="last_name"
              ref={register({ required: true })}
              className="text-box"
              placeholder="Name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm mb-2">Email</label>
            <input
              name="email_address"
              ref={register({ required: true })}
              className="text-box"
              placeholder="Your Endeavour student email"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm mb-2">
              What is your closest campus?
            </label>
            <select
              name="campus"
              ref={register({ required: true, minLength: 1 })}
              className="text-box"
              label="Select campus"
            >
              {" "}
              <option selected disabled value="">
                Please select...
              </option>
              {campusesChoices.map((campus, index) => (
                <option value={campus.value} key={index}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm mb-2">
              Which course are you studying?
            </label>
            <select
              name="modality"
              ref={register({ required: true })}
              className="text-box"
            >
              <option selected disabled value="">
                Please select...
              </option>
              {modalities.map((modality, index) => (
                <option value={modality.value} key={index}>
                  {modality.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-5 flex items-center justify-center">
            <button type="submit" className="btn-joy w-full">
              {isRegistering ? (
                <ClipLoader size={24} color={"#fff"} />
              ) : (
                "Register"
              )}
            </button>
          </div>
          <div className="mb-5 text-center text-sm">
            <Link to="/">Cancel</Link>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Register;
