import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";

const Landing = () => {
  let history = useHistory();
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // check if this is a valid
    if (!value) {
      setError(true);

      return;
    }

    //check if value is email address
    if (!value.includes("@")) {
      setError(true);
      return;
    }

    const apiUrl = await `${process.env.REACT_APP_API_URL}/students/validate/`;
    try {
      const res = await axios({
        method: "POST",
        url: apiUrl,
        data: {
          email: value.replace(/\s+/g, "").toLowerCase(),
        },
      });
      if (res.status !== 200) {
        alert("No good");
        throw Error("Couldnt find you");
      }
      setError(false);
      await localStorage.setItem("modalityId", res.data.details.modality.id);
      await localStorage.setItem("studentId", res.data.details.id);

      history.push("/home");
    } catch (error) {
      setError(true);
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("modalityId") &&
      localStorage.getItem("studentId")
    ) {
      history.push("/home");
    }
  }, [history]);

  return (
    <div
      className="px-auto py-auto min-h-screen h-full w-full bg-earth bg-cover pb-16"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://www.endeavour.edu.au/media/images/Young-woman-working-at-home-1035789156_6720x448.original.jpg)`,
      }}
    >
      <section className="px-4 h-screen">
        <div className="flex flex-wrap items-center text-center lg:text-left -mx-2 h-screen">
          <div className="w-full px-2 lg:px-64 xl:px-64 lg:pl-16 mt-10 lg:mt-0">
            <img
              src="https://oday.endeavour.edu.au/media/endeavour_digital_left_white_yw9Tv5U.png"
              alt=""
              className="h-16 w-auto"
              loading="lazy"
            />
            <h1 className="mt-4 mb-6 leading-tight font-heading text-white">
              Virtual Orientation Day
            </h1>
            <p className="text-sm text-white leading-relaxed mb-8 md:w-2/3">
              Virtual Orientation Day is an opportunity to become familiar with
              your classmates, be introduced to student support departments, and
              understand the systems you will use throughout your studies.
            </p>

            {showLogin ? (
              <>
                {error ? (
                  <div
                    className="flex items-center bg-joy text-white text-sm font-normal px-4 py-3 w-full md:w-2/3 mb-8 rounded"
                    role="alert"
                  >
                    <svg
                      className="fill-current w-4 h-4 mr-2"
                      xmlns="https://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                    </svg>
                    <p>
                      We couldn't find anything for that email address - do you
                      need to{" "}
                      <Link
                        to="/register"
                        className="font-bold underline hover:underline"
                      >
                        register
                      </Link>
                      ?
                    </p>
                  </div>
                ) : (
                  <p
                    className="
                  text-sm text-white leading-relaxed mb-8"
                  >
                    Enter your email address below to login
                  </p>
                )}
                <form
                  className="w-full max-w-lg mx-auto lg:mx-0"
                  onSubmit={handleSubmit}
                >
                  <div className="flex flex-wrap mb-6 md:mb-0">
                    <div className="w-full">
                      <input
                        className="appearance-none block w-full py-3 px-4 mb-4 focus:text-chia border focus:border-mindful rounded focus:outline-none"
                        type="text"
                        placeholder="your-student-number@eweb.endeavour.edu.au"
                        value={value}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full">
                      <button
                        type="submit"
                        className="inline-block w-full py-4 px-8 leading-none text-white bg-joy hover:bg-white hover:border-mindful  hover:text-wisdom rounded "
                      >
                        Continue
                      </button>
                    </div>
                    <p className="text-white text-sm mt-4 font-semibold hover:underline">
                      <Link to="/register">Need to register?</Link>
                    </p>
                  </div>
                </form>
              </>
            ) : (
              <div className="inline-flex gap-2">
                <Link to="/register" className="btn-joy mr-2">
                  Register
                </Link>
                <button
                  onClick={() => setShowLogin(true)}
                  className="btn-trans"
                >
                  Login
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;
