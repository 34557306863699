import React, { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import PageHeader from "../../components/PageHeader/PageHeader";

import Card from "../../components/Card/Card";

const Content = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [content, setContent] = useState([]);
  const [filteredContent, setFilteredContent] = useState([]);

  const fetchArticles = async () => {
    try {
      if (!localStorage.getItem("studentId")) {
        throw new Error("No studentId found in localstorage");
      }

      const studentId = await localStorage.getItem("studentId");
      const apiUrl = await `${process.env.REACT_APP_API_URL}/students/${studentId}/`;
      const res = await axios.get(apiUrl);

      if (res.status !== 200) {
        throw new Error("Didn't get a 200 from the API");
      }

      await setContent(res.data.articles);
      await setFilteredContent(res.data.articles);

      setTimeout(() => {
        setLoading(false);
      }, 500);
      return true;
    } catch (error) {
      await setLoading(false);
      setError(error);
      //TODO: alert user to error
      window.alert(error);
      return false;
    }
  };

  const filterContent = async (contentType) => {
    let filterIt = await content.filter((article) => {
      return article.content_type === contentType.toString();
    });
    setFilteredContent(filterIt);
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  return (
    <div>
      <>
        <PageHeader
          image={
            "https://oday.endeavour.edu.au/media/Content%20corner%20-%20main%20image.jpg"
          }
          title={"Useful Content"}
          description={
            "Here are some useful articles to read & videos to watch"
          }
        />
      </>
      <section
        id="filters"
        className="flex justify-center w-full mb-8 border-b border-offwhite"
      >
        <div>
          <button
            disabled={loading}
            onClick={() => fetchArticles()}
            className="filter-tumeric"
          >
            All
          </button>
          <button
            disabled={loading}
            onClick={() => filterContent(1)}
            className="filter-tumeric"
          >
            Endeavour Blogs
          </button>
          <button
            disabled={loading}
            onClick={() => filterContent(3)}
            className="filter-tumeric"
          >
            Student Blogs
          </button>
          <button
            disabled={loading}
            onClick={() => filterContent(4)}
            className="filter-tumeric"
          >
            Alumni Blogs
          </button>
          <button
            disabled={loading}
            onClick={() => filterContent(2)}
            className="filter-tumeric"
          >
            Videos
          </button>
        </div>
      </section>

      <section className="w-full sm:w-1/1 md:w-1/1 p-2 md:px-32 lg:px-64 text-center border-r">
        <div className="flex justify-center flex-wrap">
          {loading ? (
            <Loading loading={loading} />
          ) : error ? (
            "Something went wrong :("
          ) : (
            filteredContent.map((article, index) => (
              <Card details={article} type="content" key={index} />
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default Content;
