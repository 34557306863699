import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import Tile from "../../components/Tile/Tile";
import PageHeader from "../../components/PageHeader/PageHeader";

const Welcome = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [content, setContent] = useState({ articles: [] });
  const [tiles, setTiles] = useState({ articles: [] });

  useEffect(() => {
    const getHomeContent = async () => {
      try {
        const apiUrl = await `${process.env.REACT_APP_API_URL}/content/home/`;
        const res = await axios.get(apiUrl);

        if (res.status !== 200) {
          throw new Error("Didn't get a 200 from the API");
        }

        await getHomeTiles();
        setTimeout(() => {
          setLoading(false);
        }, 500);
        return true;
      } catch (error) {
        await setLoading(false);
        setError(error);
        window.alert(error);
        return false;
      }
    };
    const getHomeTiles = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/content/home-tiles/`;
        const res = await axios.get(apiUrl);

        if (res.status !== 200) {
          throw new Error("Didn't get a 200 from the API");
        }

        await setTiles(res.data);
        return true;
      } catch (error) {
        await setLoading(false);
        setError(error);
        window.alert(error);
        return false;
      }
    };
    getHomeContent();
  }, []);

  return (
    <div>
      <>
        <PageHeader
          image={
            "https://www.endeavour.edu.au/media/images/Young-woman-working-at-home-1035789156_6720x448.original.jpg"
          }
          title={"Welcome to Virtual Orientation Day"}
          description={
            "This is an opportunity to become familiar with your classmates, be introduced to student support departments and understand the systems you will use throughout your studies."
          }
        />
      </>

      <section className="w-full sm:w-1/1 md:w-1/1 p-2 md:px-32 lg:px-64 text-center border-r">
        <div className="flex flex-wrap justify-center">
          {loading || !tiles.length ? (
            <Loading loading={loading} />
          ) : error ? (
            "Something went wrong :("
          ) : (
            tiles.map((tile, index) => (
              <div
                className="m-auto w-full md:w-1/2 px-4 lg:mb-4 mb-8 h-auto"
                key={index}
              >
                {tile.internal_link ? (
                  <Link to={tile.internal_link}>
                    <Tile tile={tile} />
                  </Link>
                ) : (
                  <a
                    href={tile.external_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Tile tile={tile} />
                  </a>
                )}
              </div>
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default Welcome;
